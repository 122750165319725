import React, { useContext, useEffect, useState } from "react";
import Avatar from "../assets/images/profile.png";
import { Button, IconButton, Tooltip } from "@mui/material";
import { BiDotsVerticalRounded } from "react-icons/bi";
import LogoutDialog from "./helperComponents/LogoutDialog";
import axios from "axios";
import { BASE_URL, decryptStorage } from "../utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoWarning } from "react-icons/io5";
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";
import { UserDataContext } from "../AppRouter";
function Header() {
  let temp = sessionStorage.getItem("token");
  let user = temp && decryptStorage(sessionStorage.getItem("userDetails"));
  const navigate = useNavigate();
  const { showMenu, setShowMenu } = useContext(UserDataContext);
  // user = JSON.parse(user);
  const [userDetails, setUserDetails] = useState(user);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [notification, setNotification] = useState();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDialog = (e) => {
    e.preventDefault();
    setDialogOpen(true);
    handleClick(e);
  };
  useEffect(() => {
    getNotification();
  }, []);
  const getNotification = async () => {
    try {
      let response = await axios.get(BASE_URL + "/notification", {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.data.success) {
          setNotification(response.data.data.notification);
        } else {
          return;
        }
      }
    } catch (error) {
      // toast.error(error.response.data.message);
      console.log("Something went wrong");
    }
  };
  return (
    <>
      <div
        className="header-container"
        style={{
          justifyContent: "flex-end",
        }}
      >
        <div
          className="header-left-container"
          style={{
            display: "flex",
            alignItems: "center",
            width: "max-content",
          }}
        >
          {/* {sessionStorage.getItem("uuid") && (
            <p className="blink">
              <IoWarning /> "Don't lose your work! Save your progress before
              switching tabs."
            </p>
          )} */}
          <Tooltip
            title={
              showMenu ? "Show PDF Extractor Menu" : "Hide PDF Extractor Menu"
            }
          >
            <Button
              onClick={() => {
                setShowMenu(!showMenu);
              }}
              style={{
                height: "40px",
                width: "40px",
                minWidth: "auto",
                background: "#1677ff",
                marginRight: "1rem",
              }}
            >
              {showMenu ? (
                <IoEye
                  style={{
                    fontSize: "1.3rem",
                    color: "white",
                  }}
                />
              ) : (
                <IoMdEyeOff
                  style={{
                    fontSize: "1.3rem",
                    color: "white",
                  }}
                />
              )}
            </Button>
          </Tooltip>
        </div>
        <div
          className="header-right-container"
          style={{
            width: "max-content",
          }}
        >
          {/* <div className="header-menus-avatar">
            <img src={Avatar} alt="" />
          </div> */}
          <div className="header-menus-profile">
            <div id="name">
              {userDetails?.first_name + " " + userDetails?.last_name}
            </div>
            <div id="email">{userDetails?.email}</div>
          </div>
          <div style={{ position: "relative" }}>
            <IconButton onClick={handleDialog}>
              <BiDotsVerticalRounded />
            </IconButton>
            <LogoutDialog
              dialogOpen={dialogOpen}
              notification={notification}
              setNotification={setNotification}
              setDialogOpen={setDialogOpen}
              anchorEl={anchorEl}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
