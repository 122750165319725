import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import Header from "./Header";
import { UserDataContext } from "../AppRouter";
import Sidebar from "./Sidebar";
import { decryptStorage } from "../utils";
import { Flex } from "../assets/styles/Chatbot";
import ChatBotSidebar from "./AIChatbotComponents/ChatBotSidebar";
import Chat from "./AIChatbotComponents/Chat";
import PdfViewer from "./AIChatbotComponents/PDFViewer";

function AIChatbot() {
  const { sidebarView, selectedFiles, uploadedFiles } =
    useContext(UserDataContext);
  const temp = sessionStorage.getItem("token");
  const role =
    temp && decryptStorage(sessionStorage.getItem("userDetails"))?.role;

  const currentFile = useMemo(() => {
    return uploadedFiles.filter((file) => {
      return selectedFiles.includes(file.pdf_file_id);
    });
  }, [uploadedFiles, selectedFiles]);

  const leftRef = useRef();
  const rightRef = useRef();
  const resizerRef = useRef();

  const [isResizing, setIsResizing] = useState(false);
  const [initialWidth, setInitialWidth] = useState(0);
  const [initialMouseX, setInitialMouseX] = useState(0);

  const handleMouseDown = (e) => {
    setIsResizing(true);
    setInitialMouseX(e.clientX);
    setInitialWidth(leftRef.current.offsetWidth);
  };

  const handleMouseMove = (e) => {
    if (!isResizing) return;

    const deltaX = e.clientX - initialMouseX;
    const newWidth = initialWidth + deltaX;

    // Optional: Set minimum and maximum widths
    const minWidth = 200; // Minimum width for the left panel
    const maxWidth = 1000; // Maximum width for the left panel
    if (newWidth >= minWidth && newWidth <= maxWidth) {
      leftRef.current.style.width = `${newWidth}px`;
      rightRef.current.style.width = `calc(100% - ${newWidth}px - 5px)`; // Adjust for the resizer width
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  if (!sessionStorage.getItem("token")) {
    return <Navigate to={"/"}></Navigate>;
  }

  return (
    <React.Fragment>
      <div className="main-container">
        <div className={sidebarView.cssLeftContainer}>
          <Sidebar />
        </div>
        <div className={`${sidebarView.cssRightContainer} dashboard-div`}>
          <Header />
          <div
            className="right-container-content"
            style={{ padding: "5px 10px" }}
          >
            <Flex
              background="#001529"
              height="100%"
              width="100%"
              direction="row"
              style={{
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <Flex
                background="#001529"
                height="100%"
                width="280px"
                className="chatbot_sidebar_container"
                style={{
                  // borderRight: "3px solid rgb(172, 172, 172)",
                  borderRight:
                    currentFile.length === 0 ? "3px solid white" : "none",
                  overflow: "auto",
                }}
              >
                <ChatBotSidebar />
              </Flex>

              <Flex
                background="#001529"
                height="100%"
                width="35%"
                ref={leftRef}
                // flex={1}
                style={{
                  // borderRight: "3px solid rgb(172, 172, 172)",
                  padding: "2px",
                }}
              >
                <PdfViewer
                  file={currentFile}
                  id={selectedFiles}
                  key={selectedFiles.join(",")}
                />
              </Flex>

              <div
                ref={resizerRef}
                onMouseDown={handleMouseDown}
                className="resizer"
              ></div>

              <Flex ref={rightRef} background="#001529" height="100%" flex={1}>
                <Chat id={selectedFiles} key={selectedFiles.join(",")} />
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AIChatbot;
