import React, { useState, createContext } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import TaskInProgess from "./components/TaskInProgess";
import TaskCompleted from "./components/TaskCompleted";
import Reconciliated from "./components/Reconciliated";
import RulesetList from "./components/RulesetList";
import ImportHistory from "./components/ImportHistory";
import MenuButtonExpand from "./assets/images/menu-expand@2x.png";
import InputFolder from "./components/InputFolder";
import ChooseTemplate from "./components/RulesetIntermediate/ChooseTemplate";
import axios from "axios";
import { BASE_URL, decryptStorage } from "./utils";
import CreateRuleset from "./components/RulesetIntermediate/CreateRuleset";
import OutputFolder from "./components/OutputFolder";
import CreateCustomCsv from "./components/RulesetIntermediate/CreateCustomCsv";
import CustomCSVTable from "./components/RulesetIntermediate/CustomCSVTable";
import UploadCustomCSV from "./components/helperComponents/UploadCustomCSV";
import ApplyReconciliation from "./components/ApplyReconciliation";
import { Navigate } from "react-router-dom/dist";
import NewLogin from "./components/NewLogin";
import ResetPassword from "./components/ResetPassword";
import ForgetPassword from "./components/ForgetPassword";
import AdminSetting from "./components/AdminSetting";
import ReconciliatedLoadingPage from "./components/helperComponents/ReconciliatedLoadingPage";
import ReconciliatedResult from "./components/ReconciliatedResult";
import moment from "moment/moment";
import CicularLoader from "./components/ReusableComponents/CicularLoader";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, toast } from "react-toastify";
import MaskingPage from "./components/MaskingComponents/MaskingPage";
import AIChatbot from "./components/AIChatbot";

export const UserDataContext = createContext();

function AppRouter() {
  const temp = sessionStorage.getItem("token");
  const [userInputData, setUserInputData] = useState([]);
  const [userOutputData, setUserOutputData] = useState([]);
  const [ruleSetData, setRuleSetData] = useState([]);
  const [reconciliatedData, setReconciliatedData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [userData, setUserData] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showMenu, setShowMenu] = useState(true);
  const [rulesetTemplate, setRulesetTemplate] = useState();
  const [cicularLoaderStatus, setCicularLoaderStatus] = useState();
  const [rowCol, setRowCol] = useState({
    row: 0,
    column: 0,
  });
  const [uploadedCSV, setUploadedCsv] = useState([]);
  const [sidebarView, setSidebarView] = useState({
    value: false,
    cssClassSidebar: "container",
    icon: MenuButtonExpand,
    cssLeftContainer: "left-container",
    cssRightContainer: "right-container",
  });
  const [selectedTemplate, setSelectedTemplate] = useState();

  const handleRulesetList = async (
    filter = { ruleset_name: "", start_date: "", end_date: "" },
    setisloading
  ) => {
    try {
      if (typeof setisloading === "function") {
        setisloading(true);
      }
      const isAdmin = decryptStorage(
        sessionStorage.getItem("userDetails")
      ).is_admin;
      let url = isAdmin ? "/admin/rule-set" : "/rule-set";

      let response = await axios.get(BASE_URL + url, {
        headers: {
          "X-Auth-Token": temp,
        },
        params: {
          ruleset_name: filter.filename,
          start_date:
            filter.start_date !== ""
              ? moment(filter.start_date).format("MM/DD/YYYY")
              : "",
          end_date:
            filter.end_date !== ""
              ? moment(filter.end_date).format("MM/DD/YYYY")
              : "",
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (typeof setisloading === "function") {
          setisloading(false);
        }
        if (response.data.success) {
          setRuleSetData(response?.data?.data);
        } else {
          return;
        }
      }
    } catch (error) {
      if (typeof setisloading === "function") {
        setisloading(false);
      }
      console.log(error.response?.data?.message);
      // toast.error(error.response?.data?.message);
    }
  };
  const getRulesetTemplate = async () => {
    try {
      let response = await axios.get(BASE_URL + "/ruleset-template", {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (response.status === 200) {
          setRulesetTemplate(response?.data?.data);
        } else {
          return;
        }
      }
    } catch (error) {
      console.log(error.response?.data?.message);
      // toast.error(error.response?.data?.message);
    }
  };

  const getUploadedFiles = async (token) => {
    try {
      let response = await axios.get(BASE_URL + "/user-pdf-info", {
        headers: {
          "X-Auth-Token": temp || token,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (response.status === 200) {
          const { data } = response;
          setUploadedFiles(data.data);
        } else {
          return;
        }
      }
    } catch (error) {
      console.log(error.response?.data?.message);
      // toast.error(error.response?.data?.message);
    }
  };

  const propValue = {
    userInputData,
    setUserInputData,
    userOutputData,
    setUserOutputData,
    userData,
    setUserData,
    sidebarView,
    setSidebarView,
    ruleSetData,
    handleRulesetList,
    rowCol,
    setRowCol,
    uploadedCSV,
    setUploadedCsv,
    rulesetTemplate,
    setRulesetTemplate,
    getRulesetTemplate,
    selectedTemplate,
    setSelectedTemplate,
    reconciliatedData,
    setReconciliatedData,
    selectedData,
    setSelectedData,
    cicularLoaderStatus,
    setCicularLoaderStatus,
    selectedFiles,
    setSelectedFiles,
    uploadedFiles,
    setUploadedFiles,
    getUploadedFiles,
    setShowMenu,
    showMenu,
  };

  (() => {
    if (!sessionStorage.getItem("token")) {
      return <Navigate to="/" />;
    }
  })();

  const UnknownURL = () => {
    if (sessionStorage.getItem("token")) {
      return <Navigate to={`/dashboard`} />;
    }
    return <Navigate to="/" />;
  };

  return (
    <div className="App">
      <UserDataContext.Provider value={propValue}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<NewLogin />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route path="/pdfmasking" element={<MaskingPage />} />

            {/* PRIVATE ROUTES */}

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/inputfolder" element={<InputFolder />} />
            <Route path="/outputfolder" element={<OutputFolder />} />
            <Route path="/taskinprogress" element={<TaskInProgess />} />
            <Route path="/taskcompleted" element={<TaskCompleted />} />
            <Route
              path="/applyreconciliation"
              element={<ApplyReconciliation />}
            />
            <Route path="/reconciliated" element={<Reconciliated />} />
            <Route
              path="/reconciliated-result"
              element={<ReconciliatedResult />}
            />
            <Route path="/rulesetlist" element={<RulesetList />} />
            <Route path="/importhistory" element={<ImportHistory />} />
            <Route path="/adminsetting" element={<AdminSetting />} />
            <Route
              path="/createNewRuleset/choosetemplate"
              element={<ChooseTemplate />}
            />
            <Route
              path="/createNewRuleset/createRuleset"
              element={<CreateRuleset />}
            />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/resetPassword/*" element={<ResetPassword />} />
            <Route path="/create-custom-csv" element={<CreateCustomCsv />} />
            <Route
              path="/create-custom-csv/custom-table"
              element={<CustomCSVTable />}
            />
            <Route
              path="/create-custom-csv/uploaded-csv"
              element={<UploadCustomCSV />}
            />
            <Route
              path="/applyreconciliation/reconciliationstarted"
              element={<ReconciliatedLoadingPage />}
            />

            <Route path="/chatbot" element={<AIChatbot />} />
            <Route path="*" element={<UnknownURL />} />
          </Routes>
        </BrowserRouter>
      </UserDataContext.Provider>

      <ToastContainer
        position="top-right"
        hideProgressBar
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
      {cicularLoaderStatus && <CicularLoader />}
    </div>
  );
}

export default AppRouter;
