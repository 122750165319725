import React, { useEffect, useState } from "react";
import { Flex } from "../../assets/styles/Chatbot";
import Loader from "./Loader";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";
import { Button, CircularProgress } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { FaExpand } from "react-icons/fa";
import PreviewExcel from "./PreviewExcel";
import NoData from "./NoData";

function ExcelView({ id }) {
  const [data, setData] = useState(() => []);
  const [ogdata, setOgData] = useState(() => []);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const temp = sessionStorage.getItem("token");
  const [columns, setColumns] = useState(() => Object.keys(data[0] || {}));
  const [openExcelPreview, setOpenExcelPreview] = useState({
    open: false,
    data: null,
  });

  const HandlePreviewExcel = (answer) => {
    setOpenExcelPreview({
      open: true,
      data: answer,
    });
  };

  const HandleExcelClose = () => {
    setOpenExcelPreview({
      open: false,
      data: null,
    });
  };

  const HandleDownloadFile = (response) => {
    const fileType = response.filename?.split(".")[1];
    const fileName = response.filename?.split(".")[0];

    try {
      const mimeType =
        fileType === "xlsx"
          ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          : "text/csv";

      const byteCharacters = atob(response.file);
      const byteNumbers = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteNumbers], { type: mimeType });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = `${fileName}.${fileType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setDownloadLoading(false);
    } catch (error) {
      console.log("failed to download file", error);
      setDownloadLoading(false);
    }
  };

  const handleInputChange = (rowIndex, columnKey, value) => {
    const newData = data.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, [columnKey]: value };
      }
      return row;
    });
    setData(newData);
  };

  const HandleDownload = () => {
    setDownloadLoading(true);
    axios
      .post(
        BASE_URL + "/save-table-file",
        {
          filetype: "xlsx",
          table: data,
        },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      )
      .then((response) => {
        if (response?.data.blocked) {
          toast.error("Please Login Again");
          sessionStorage.clear();
          window.location.reload();
        } else {
          if (response.status === 200) {
            HandleDownloadFile(response.data.data);
          } else {
            return;
          }
        }
      })
      .catch((error) => {
        setDownloadLoading(false);
        toast.error(error.response?.data?.message);
      });
  };

  const addColumn = () => {
    const newColumnName = prompt("Enter new column name:");
    if (newColumnName) {
      const updatedData = data.map((row) => ({
        ...row,
        [newColumnName]: "", // Add new column with empty value
      }));
      setData(updatedData);
      setColumns([...columns, newColumnName]);
    }
  };

  const removeColumn = (columnName) => {
    const updatedData = data.map((row) => {
      const { [columnName]: _, ...rest } = row; // Destructure to remove the column
      return rest;
    });

    const updatedColumns = columns.filter((col) => col !== columnName);

    setData(updatedData);
    setColumns(updatedColumns);
  };

  useEffect(() => {
    const getExcelData = async () => {
      setLoading(true);
      try {
        let response = await axios.post(
          BASE_URL + "/generate-excel",
          { pdf_ids: id },
          {
            headers: {
              "X-Auth-Token": temp,
            },
          }
        );
        if (response?.data.blocked) {
          toast.error("Please Login Again");
          sessionStorage.clear();
          window.location.reload();
        } else {
          if (response.status === 200) {
            console.log(response);
            setData(JSON.parse(response.data.data.excel));
            setOgData(JSON.parse(response.data.data.excel));
            setColumns(Object.keys(JSON.parse(response.data.data.excel)[0]));
            setLoading(false);
          } else {
            return;
          }
        }
      } catch (error) {
        toast.error(error.response?.data?.message);
        setLoading(false);
      }
    };
    if (id.length > 0) {
      getExcelData();
    }
  }, []);

  return (
    <React.Fragment>
      <Flex width="100%" height="100%" background="white" direction="column">
        <Flex
          width="100%"
          background="white"
          justify="space-between"
          align="center"
          padding="10px"
        >
          <Button
            onClick={() => {
              HandlePreviewExcel({
                table: data,
              });
            }}
          >
            <FaExpand
              style={{
                color: "black",
                fontSize: "1.2rem",
              }}
            />
          </Button>
          <Flex
            align="center"
            gap="8px"
            style={{
              flexWrap: "wrap",
            }}
          >
            <Button
              sx={{
                textTransform: "none",
                fontSize: "0.5rem",
                borderColor: "#1677ff",
              }}
              variant="outlined"
              onClick={() => {
                setData(ogdata);
                setColumns(Object.keys(ogdata[0] || {}));
              }}
            >
              Reset
            </Button>

            <Button
              sx={{
                textTransform: "none",
                fontSize: "0.5rem",
                backgroundColor: "#1677ff",
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
              variant="contained"
              onClick={HandleDownload}
              disabled={downloadLoading}
            >
              {downloadLoading && <CircularProgress size={"20px"} />}
              Download File
            </Button>

            <Button
              sx={{
                textTransform: "none",
                fontSize: "0.5rem",
                backgroundColor: "#1677ff",
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
              variant="contained"
              onClick={addColumn}
            >
              Add Column
            </Button>
          </Flex>
        </Flex>
        <Flex
          width="100%"
          height="100%"
          background="white"
          style={{
            overflow: "auto",
            padding: "8px",
          }}
        >
          {loading && <Loader />}

          {!data.length && !loading && (
            <NoData text="Data Not found" color="gray" />
          )}

          {data.length > 0 && (
            <Flex
              style={{
                overflow: "auto",
                height: "88%",
              }}
              className="excel_table_container"
              width="100%"
            >
              <table className="excel_table">
                <thead>
                  <tr className="excel_table_row">
                    {columns.map((col, i) => (
                      <th
                        className="excel_table_head"
                        key={`${col}-${i}`}
                        style={{
                          fontSize: "0.6rem",
                        }}
                      >
                        {col}
                        <span
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            width: "10px",
                            height: "10px",
                          }}
                          className="remove-column-icon"
                          onClick={() => removeColumn(col)}
                        >
                          <IoMdClose
                            style={{
                              color: "white",
                              fontSize: "0.6rem",
                            }}
                          />
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {data.map((row, i) => (
                    <tr className="excel_table_row" key={i}>
                      {columns.map((rowVal, j) => (
                        <td
                          className="excel_table_data"
                          key={`${i}-${j}-${rowVal}`}
                        >
                          <input
                            type="text"
                            className="excel_table_input"
                            value={row[rowVal]}
                            style={{
                              maxWidth: "100px",
                              fontSize: "0.6rem",
                            }}
                            onChange={(e) => {
                              handleInputChange(i, rowVal, e.target.value);
                            }}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Flex>
          )}
        </Flex>
      </Flex>

      {openExcelPreview.open && (
        <PreviewExcel
          state={openExcelPreview}
          HandleExcelClose={HandleExcelClose}
        />
      )}
    </React.Fragment>
  );
}

export default ExcelView;
