import axios from "axios";

export function ReactAWSS3(file, uuid, setProgress, fileItem, signedURL) {
  // Assuming signedURL is generated server-side and passed to this function
  const config = {
    headers: {
      "Content-Type": file.type,
    },
    onUploadProgress: (evt) => {
      // File uploading progress
      setProgress((prev) => {
        const newProgress = [...prev];
        newProgress[fileItem] = Math.round((evt.loaded * 100) / evt.total);
        return newProgress;
      });
    },
  };

  return axios
    .put(signedURL, file, config)
    .then((response) => {
      // Handle success response if needed
      console.log("File uploaded successfully:", response.data);
      return response.data; // You might return additional data if needed
    })
    .catch((error) => {
      console.error("Upload failed:", error);
      // throw error; // Handle errors as needed
    });
}
