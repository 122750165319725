import React from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import Report from "./Report";
import { RxCross2 } from "react-icons/rx";

function ReportFSModel({ state, HandleClose }) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          overflow: "visible",
          borderRadius: "5px",
          maxWidth: "90%",
          width: "100%",
          height: "85%",
          background: "white",
        },
      }}
      onClose={HandleClose}
      open={state.open}
    >
      <Box
        style={{
          background: "white",
          position: "relative",
          height: "100%",
        }}
      >
        <div
          className="cancle-btn"
          style={{
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "500px",
            background: "#AC2734 0% 0% no-repeat padding-box",
            position: "absolute",
            top: "-10px",
            right: "-10px",
            cursor: "pointer",
            zIndex: "1000",
          }}
          onClick={HandleClose}
        >
          <RxCross2
            style={{
              fontSize: "1.2rem",
              color: "white",
              pointerEvents: "none",
            }}
          />
        </div>
        <DialogContent
          style={{
            padding: "0px",
            height: "100%",
            width: "100%",
            padding: "1rem ",
            position: "relative",
          }}
        >
          {" "}
          <Report tableData={state.data} loading={state.loading} />
        </DialogContent>
      </Box>
    </Dialog>
  );
}

export default ReportFSModel;
