import html2canvas from "html2canvas";
import React, { useEffect, useRef, useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const COLORS = [
  "#4f3b78",
  "#2470a0",
  "#fc3a52",
  "#61b390",
  "#c19898",
  "#4586ff",
  "#fb90b7",
  "#b9d4f1",
  "#e99b9b",
  "#414141",
  "#8200ff",
  "#255965",
];

const AssetPieChart = ({ setChartData, data }) => {
  const chartRef = useRef();

  const generateImage = () => {
    // Adding a small delay to ensure the chart is fully rendered before capture
    setTimeout(() => {
      if (chartRef.current) {
        html2canvas(chartRef.current).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          setChartData(imgData);
        });
      }
    }, 2000); // 500ms delay
  };

  useEffect(() => {
    if (chartRef.current) {
      generateImage();
    }
  }, []);

  return (
    <>
      <div
        className=""
        ref={chartRef}
        style={{
          width: "fit-content",
          background: "#f1f1f1",
        }}
      >
        <PieChart width={200} height={200}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            dataKey="value"
            paddingAngle={2}
            innerRadius={70}
            outerRadius={90}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </div>
    </>
  );
};

export default AssetPieChart;
