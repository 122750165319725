import axios from "axios";
import { BASE_URL } from ".";
import { toast } from "react-toastify";

const getSingedUrl = async (fileName, fileType, navigate, uuid) => {
  const temp = sessionStorage.getItem("token");

  try {
    let response = await axios.get(
      BASE_URL + `/signed-url?object_name=${fileName}&uuid=${uuid}`,
      {
        headers: {
          "X-Auth-Token": temp,
        },
      }
    );
    if (response?.data.blocked) {
      toast.error("Please Login Again");
      sessionStorage.clear();
      navigate("/");
    } else {
      if (response.status === 200) {
        return response.data.data.url;
      } else {
        return;
      }
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
export default getSingedUrl;
