import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import NoData from "./NoData";
import { MdDeleteOutline } from "react-icons/md";
import { Flex } from "../../assets/styles/Chatbot";
import axios from "axios";
import { BASE_URL, decryptStorage } from "../../utils";
import { toast } from "react-toastify";
import EditInstructionDialog from "./EditInstructionDialog";
import { RxCross1 } from "react-icons/rx";
import DeleteInstructionModal from "./DeleteInstructionModal";

function CustomizeInstructionsDialog({ isopen, onClose }) {
  const [loading, setLoading] = useState(false);
  const [textArea, showTextArea] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState({
    open: false,
    data: null,
  });
  const temp = sessionStorage.getItem("token");
  const role = decryptStorage(sessionStorage.getItem("userDetails"))?.role;
  const [selectedInstruction, setSelectedInstruction] = useState(null);
  const [currentInstructions, setCurrentInstructions] = useState([]);
  const [showFullInstruction, setShowFullInstruction] = useState(null);
  const [openEditConvo, setOpenEditConvo] = useState({
    open: false,
    data: null,
  });

  const [instructionValue, setInstructionValue] = useState("");
  const HandleSave = async () => {
    if (textArea) {
      setLoading(true);
      try {
        let response = await axios({
          method: "post",
          url: `${BASE_URL}/add-instruction`,
          data: {
            instruction: instructionValue,
          },
          headers: {
            "X-Auth-Token": temp,
          },
        });
        if (response?.data.blocked) {
          toast.error("Please Login Again");
          sessionStorage.clear();
          window.location.reload();
        } else {
          if (response.status === 201) {
            setLoading(false);
            showTextArea(false);
            setInstructionValue("");
            toast.success(response.data.message);
          } else {
            return;
          }
        }
      } catch (error) {
        // console.log(error.response?.data?.message);
        toast.error(error.response?.data?.message);
        setLoading(false);
        showTextArea(false);
      }
    } else {
      setLoading(true);
      try {
        let response = await axios({
          method: "patch",
          url: `${BASE_URL}/select-instruction`,
          data: {
            instruction_id: selectedInstruction,
          },
          headers: {
            "X-Auth-Token": temp,
          },
        });
        if (response?.data.blocked) {
          toast.error("Please Login Again");
          sessionStorage.clear();
          window.location.reload();
        } else {
          if (response.status === 200) {
            setLoading(false);
            //   setSelectedInstruction(null);
            showTextArea(false);
            toast.success(response.data.message);
          } else {
            return;
          }
        }
      } catch (error) {
        // console.log(error.response?.data?.message);
        toast.error(error.response?.data?.message);
        setLoading(false);
      }
    }
  };

  const getInstructions = async () => {
    try {
      let response = await axios({
        method: "get",
        url: `${BASE_URL}/get-instructions`,
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (response.status === 200) {
          response.data.data.forEach((instr) => {
            if (instr.selected) {
              setSelectedInstruction(instr.id);
            }
          });
          setCurrentInstructions(response.data.data);
        } else {
          return;
        }
      }
    } catch (error) {
      // console.log(error.response?.data?.message);
      toast.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    if (isopen) {
      getInstructions();
    }
  }, [isopen, textArea, openEditConvo.open]);

  const HandleInstructionDelete = async (id) => {
    try {
      let response = await axios({
        method: "delete",
        url: `${BASE_URL}/delete-instruction`,
        data: {
          instruction_id: id,
        },
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (response.status === 200) {
          setopenDeleteModal({
            open: false,
            data: null,
          });
          getInstructions();
          toast.success(response.data.message);
        } else {
          return;
        }
      }
    } catch (error) {
      // console.log(error.response?.data?.message);
      toast.error(error.response?.data?.message);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={isopen}
        onClose={() => {
          setLoading(false);
          showTextArea(false);
          setSelectedInstruction(null);
          setInstructionValue("");
          setShowFullInstruction(null);
          onClose();
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Customize intructions
          <Flex
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setLoading(false);
              showTextArea(false);
              setSelectedInstruction(null);
              setInstructionValue("");
              setShowFullInstruction(null);
              onClose();
            }}
          >
            <RxCross1
              style={{
                fontSize: "1.2rem",
              }}
            />
          </Flex>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "470px",
          }}
        >
          {role === "Admin" && (
            <Button
              onClick={() => {
                showTextArea(true);
              }}
              sx={{
                textTransform: "none",
                backgroundColor: "#1677ff",
                fontSize: "0.8rem",
              }}
              variant="contained"
            >
              Add Instructions
            </Button>
          )}

          {textArea && (
            <textarea
              placeholder="Enter custom conversation here..."
              className="custom_textarea"
              autoSize={{ minRows: 7 }}
              value={instructionValue}
              onChange={(e) => {
                setInstructionValue(e.target.value);
              }}
              style={{
                marginTop: "1rem",
                fontSize: "0.9rem",
                width: "100%",
              }}
            ></textarea>
          )}

          {!textArea && (
            <Flex direction="column" className="radio-main-box">
              <span
                style={{
                  margin: "1rem 0rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Select Instructions:
              </span>
              {currentInstructions.length === 0 && (
                <NoData width={"200px"} height={"200px"} />
              )}

              {/* FROM */}

              <Flex
                direction="column"
                width="100%"
                gap="0.6rem"
                className="radio-group"
              >
                {currentInstructions.length > 0 && (
                  <Flex
                    align="center"
                    width="100%"
                    key={"null-instr"}
                    className="radio-option"
                  >
                    <input
                      type="radio"
                      name="instruction"
                      value={null}
                      // checked={selectedInstruction === instr.id}
                      onChange={(e) => setSelectedInstruction(e.target.value)}
                      style={{
                        marginRight: "0.8rem",
                      }}
                    />

                    <span
                      style={{
                        maxWidth: "350px",
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textAlign: "left",
                        display: "inline-block",
                        color: "lightgray",
                      }}
                    >
                      None
                    </span>
                  </Flex>
                )}

                {/* FROM BE */}

                {currentInstructions.map((instr) => (
                  <Flex
                    align="center"
                    width="100%"
                    key={instr.id}
                    className="radio-option"
                  >
                    <input
                      type="radio"
                      name="instruction"
                      value={instr.id}
                      checked={selectedInstruction === instr.id}
                      onChange={(e) => setSelectedInstruction(e.target.value)}
                      style={{
                        marginRight: "0.8rem",
                      }}
                    />
                    {showFullInstruction === instr.id ? (
                      <Input
                        placeholder="Enter custom conversation here..."
                        //   autoSize={{ minRows: 2, maxRows: 6 }}

                        value={instr.instruction}
                        readOnly
                        style={{
                          maxWidth: "350px",
                          width: "100%",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          maxWidth: "350px",
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          textAlign: "left",
                          display: "inline-block",
                        }}
                        onClick={() => {
                          setShowFullInstruction(instr.id);
                        }}
                      >
                        {instr.instruction}
                      </span>
                    )}

                    {role === "Admin" && (
                      <>
                        <Flex
                          style={{
                            marginLeft: "0.5rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // HandleInstructionDelete(instr.id);
                            setOpenEditConvo({
                              open: true,
                              data: {
                                ...instr,
                              },
                            });
                          }}
                        >
                          <CiEdit
                            style={{
                              fontSize: "1rem",
                            }}
                          />
                        </Flex>
                        <Flex
                          style={{
                            marginLeft: "0.5rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setopenDeleteModal({
                              open: true,
                              data: instr.id,
                            });
                            // HandleInstructionDelete(instr.id);
                          }}
                        >
                          <MdDeleteOutline
                            style={{
                              color: "red",
                              fontSize: "1rem",
                            }}
                          />
                        </Flex>
                      </>
                    )}
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            key="back"
            variant="outlined"
            color="error"
            sx={{
              textTransform: "none",
              fontSize: "0.8rem",
            }}
            onClick={() => {
              setLoading(false);
              showTextArea(false);
              setSelectedInstruction(null);
              setInstructionValue("");
              setShowFullInstruction(null);
              onClose();
            }}
          >
            Cancel
          </Button>

          <Button
            key="submit"
            variant="contained"
            //   loading={loading}
            onClick={HandleSave}
            sx={{
              backgroundColor: "#1677ff",
              textTransform: "none",
              fontSize: "0.8rem",
            }}
          >
            {loading && (
              <CircularProgress
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                }}
              />
            )}
            {textArea ? "Save instruction" : "Select instruction"}
          </Button>
        </DialogActions>
      </Dialog>

      {openEditConvo && (
        <EditInstructionDialog
          isopen={openEditConvo}
          setConvoClose={setOpenEditConvo}
        />
      )}

      {openDeleteModal.open && (
        <DeleteInstructionModal
          open={openDeleteModal}
          setOpen={setopenDeleteModal}
          HandledeleteInstruction={HandleInstructionDelete}
        />
      )}
    </React.Fragment>
  );
}

export default CustomizeInstructionsDialog;
