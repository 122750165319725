import React, { useState } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import Logo from "../../assets/images/Foliomax_LogoBlack.png";
import AssetPieChart from "./AssetChart";
import { Flex } from "../../assets/styles/Chatbot";
import { CircularProgress } from "@mui/material";
import CoutureFont from "../../assets/couture-bld.otf";

Font.registerHyphenationCallback((word) => ["", word, ""]); // Prevent hyphenation
Font.register({
  family: "Couture",
  src: CoutureFont,
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: "white",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10, // Space between tables
    borderColor: "lightgray",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 2,
    borderColor: "lightgray",
  },
  tableCell: {
    fontSize: 5,
    textAlign: "center",
    color: "#2d2a26",
  },
});

// Function to split columns into manageable chunks
const splitColumns = (columns, chunkSize) => {
  const result = [];
  for (let i = 0; i < columns.length; i += chunkSize) {
    result.push(columns.slice(i, i + chunkSize));
  }
  return result;
};

// Estimate the height of each row and total table height based on number of rows and columns
const estimateTableHeight = (rows) => {
  const rowHeight = 12; // Approximate height of each row
  const headerHeight = 14; // Height of the table header
  return headerHeight + rows.length * rowHeight;
};

const COLORS = [
  "#4f3b78",
  "#2470a0",
  "#fc3a52",
  "#61b390",
  "#c19898",
  "#4586ff",
  "#fb90b7",
  "#b9d4f1",
  "#e99b9b",
  "#414141",
  "#8200ff",
  "#255965",
];

const getFormattedDateLong = () => {
  const today = new Date();

  // Array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get day, month, and year
  const day = today.getDate(); // Day of the month (1-31)
  const month = monthNames[today.getMonth()]; // Get full month name
  const year = today.getFullYear(); // Four-digit year

  // Format as "Month Day, Year"
  return `${month} ${day}, ${year}`;
};

// Example usage
const formattedDate = getFormattedDateLong();

// Define the PDF document component
const MyDocument = ({ tables, chartData, chartsubData }) => {
  const pageHeight = 800; // Approximate height of a PDF page
  const rowHeight = 12; // Approximate height of each row
  const headerHeight = 14; // Approximate height of the table header

  let currentPageHeight = 0; // Track height on the current page

  return (
    <Document>
      <Page size="A4" style={styles.page} key={`${1}-${2}`}>
        <View
          fixed
          style={{
            marginBottom: "25",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Image
            src={Logo}
            style={{
              width: "100",
              objectFit: "contain",
            }}
          ></Image>

          <Text
            style={{
              fontSize: "9",
              fontFamily: "Couture",
            }}
          >
            {formattedDate}
          </Text>
        </View>

        <View
          style={{
            marginBottom: "20",
            display: "flex",
            flexDirection: "row",
            gap: "5",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          {tables["Asset Class Allocation Summary"] && (
            <View
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
                borderColor: "#cccee0",
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                {tables["Asset Class Allocation Summary"].map(
                  (table, tableIndex) => {
                    const splitTables = splitColumns(table.columns, 20); // Split large tables into chunks if more than 20 columns

                    return splitTables.map((tableColumns, chunkIndex) => {
                      const tableHeight = estimateTableHeight(table.rows); // Estimate table height based on the number of rows

                      // Check if the table fits on the current page
                      const isNewPageNeeded =
                        currentPageHeight + tableHeight > pageHeight;

                      if (isNewPageNeeded) {
                        currentPageHeight = 0; // Reset the page height if starting a new page
                      }
                      currentPageHeight += tableHeight;

                      return (
                        <View
                          style={{
                            borderRadius: 5,
                            overflow: "hidden",
                            backgroundColor: "#f1f1f1",
                          }}
                        >
                          <Text
                            style={{
                              width: "100%",
                              backgroundColor: "#00586f",
                              textAlign: "left",
                              color: "white",
                              padding: "7px",
                              fontSize: "6px",
                              fontWeight: "600",
                              fontFamily: "Couture",
                            }}
                          >
                            {table.title}
                          </Text>

                          <View
                            style={{
                              ...styles.table,
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              overflow: "hidden",
                            }}
                          >
                            {/* Table Header */}
                            <View
                              style={{
                                ...styles.tableRow,
                                backgroundColor: "#F1F1F1",
                              }}
                            >
                              {tableColumns.map((col, colIndex) => (
                                <View
                                  style={[
                                    styles.tableCol,
                                    {
                                      width: `${100 / tableColumns.length}%`,
                                      backgroundColor: "#7aa5ba",
                                    },
                                  ]}
                                  key={colIndex}
                                >
                                  <Text
                                    style={{
                                      ...styles.tableCell,
                                      fontWeight: "bold",
                                      fontSize: "5",
                                      padding: "1",
                                      color: "white",
                                      fontFamily: "Couture",
                                    }}
                                  >
                                    {col}
                                  </Text>
                                </View>
                              ))}
                            </View>

                            {/* Table Body */}
                            {table.rows.map((row, rowIndex) => {
                              let isTotal = false;
                              if (rowIndex + 1 === table.rows.length) {
                                isTotal = true;
                              }
                              return (
                                <View style={styles.tableRow} key={rowIndex}>
                                  {tableColumns.map((_, colIndex) => (
                                    <View
                                      style={[
                                        styles.tableCol,
                                        {
                                          width: `${
                                            100 / tableColumns.length
                                          }%`,
                                          backgroundColor: "white",
                                        },
                                      ]}
                                      key={colIndex}
                                    >
                                      <Text
                                        style={{
                                          ...styles.tableCell,
                                          fontFamily: isTotal
                                            ? "Helvetica-Bold"
                                            : "",
                                        }}
                                      >
                                        {
                                          row[
                                            table.columns.indexOf(
                                              tableColumns[colIndex]
                                            )
                                          ]
                                        }
                                      </Text>
                                    </View>
                                  ))}
                                </View>
                              );
                            })}
                          </View>
                        </View>
                      );
                    });
                  }
                )}
              </View>
              {chartData && (
                <View
                  style={{
                    width: "80",
                    backgroundColor: "#F1F1F1",
                    padding: "2",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={chartData}
                    style={{
                      width: "60%",
                      height: "auto",
                      objectFit: "contain",
                      marginBottom: "2",
                    }}
                  ></Image>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {tables["pie-chart"]["pie-chart-asset"].map((row, i) => {
                      return (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "3",
                            marginBottom: "2",
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: `${COLORS[i]}`,
                              width: "10",
                              height: "10",
                              marginBottom: "1.6",
                            }}
                          ></View>
                          <Text
                            style={{
                              fontSize: "5",
                              width: "60",
                              textOverflow: "ellipsis",
                              color: "#2d2a26",
                              fontFamily: "Couture",
                            }}
                          >
                            {row.name}:{row.value}%
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                </View>
              )}
            </View>
          )}

          {/* =========================SuBCLASS TABLE=========================== */}
          {/* =========================SuBCLASS TABLE=========================== */}
          {/* =========================SuBCLASS TABLE=========================== */}
          {/* =========================SuBCLASS TABLE=========================== */}
          {/* =========================SuBCLASS TABLE=========================== */}
          {/* =========================SuBCLASS TABLE=========================== */}

          {tables["Sub Class Allocation Summary"] && (
            <View
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                {tables["Sub Class Allocation Summary"].map(
                  (table, tableIndex) => {
                    const splitTables = splitColumns(table.columns, 20); // Split large tables into chunks if more than 20 columns

                    return splitTables.map((tableColumns, chunkIndex) => {
                      const tableHeight = estimateTableHeight(table.rows); // Estimate table height based on the number of rows

                      // Check if the table fits on the current page
                      const isNewPageNeeded =
                        currentPageHeight + tableHeight > pageHeight;

                      if (isNewPageNeeded) {
                        currentPageHeight = 0; // Reset the page height if starting a new page
                      }
                      currentPageHeight += tableHeight;

                      return (
                        <View
                          style={{
                            borderRadius: 5,
                            overflow: "hidden",
                            backgroundColor: "#f1f1f1",
                          }}
                        >
                          <Text
                            style={{
                              width: "100%",
                              backgroundColor: "#00586f",
                              textAlign: "left",
                              color: "white",
                              padding: "7px",
                              fontSize: "6px",
                              fontWeight: "600",
                              fontFamily: "Couture",
                            }}
                          >
                            {table.title}
                          </Text>

                          <View
                            style={{
                              ...styles.table,
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              overflow: "hidden",
                            }}
                          >
                            {/* Table Header */}
                            <View
                              style={{
                                ...styles.tableRow,
                                backgroundColor: "#7aa5ba",
                              }}
                            >
                              {tableColumns.map((col, colIndex) => (
                                <View
                                  style={[
                                    styles.tableCol,
                                    { width: `${100 / tableColumns.length}%` },
                                  ]}
                                  key={colIndex}
                                >
                                  <Text
                                    style={{
                                      ...styles.tableCell,
                                      fontWeight: "bold",
                                      fontSize: "5",
                                      padding: "1",
                                      color: "white",
                                      fontFamily: "Couture",
                                    }}
                                  >
                                    {col}
                                  </Text>
                                </View>
                              ))}
                            </View>

                            {/* Table Body */}
                            {table.rows.map((row, rowIndex) => {
                              let isTotal = false;
                              if (rowIndex + 1 === table.rows.length) {
                                isTotal = true;
                              }

                              return (
                                <View style={styles.tableRow} key={rowIndex}>
                                  {tableColumns.map((_, colIndex) => (
                                    <View
                                      style={[
                                        styles.tableCol,
                                        {
                                          width: `${
                                            100 / tableColumns.length
                                          }%`,
                                          backgroundColor: "white",
                                        },
                                      ]}
                                      key={colIndex}
                                    >
                                      <Text
                                        style={{
                                          ...styles.tableCell,
                                          fontFamily: isTotal
                                            ? "Helvetica-Bold"
                                            : "",
                                        }}
                                      >
                                        {
                                          row[
                                            table.columns.indexOf(
                                              tableColumns[colIndex]
                                            )
                                          ]
                                        }
                                      </Text>
                                    </View>
                                  ))}
                                </View>
                              );
                            })}
                          </View>
                        </View>
                      );
                    });
                  }
                )}
              </View>

              {chartsubData && (
                <View
                  style={{
                    width: "80",
                    backgroundColor: "#F1F1F1",
                    padding: "2",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={chartsubData}
                    style={{
                      width: "60%",
                      height: "auto",
                      objectFit: "contain",
                      marginBottom: "2",
                    }}
                  ></Image>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {tables["pie-chart"]["pie-chart-sub"].map((row, i) => {
                      return (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "3",
                            marginBottom: "2",
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: `${COLORS[i]}`,
                              width: "10",
                              height: "10",
                              marginBottom: "1.6",
                            }}
                          ></View>
                          <Text
                            style={{
                              fontSize: "5",
                              width: "60",
                              textOverflow: "ellipsis",
                              color: "#2d2a26",
                              fontFamily: "Couture",
                            }}
                          >
                            {row.name}:{row.value}%
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                </View>
              )}
            </View>
          )}
        </View>

        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}
        {/* --------------------Acutal Tables --------------------------- */}

        {tables.tables.map((table, tableIndex) => {
          const splitTables = splitColumns(table.columns, 20); // Split large tables into chunks if more than 20 columns

          return splitTables.map((tableColumns, chunkIndex) => {
            const tableHeight = estimateTableHeight(table.rows); // Estimate table height based on the number of rows

            // Check if the table fits on the current page
            const isNewPageNeeded =
              currentPageHeight + tableHeight > pageHeight;

            if (isNewPageNeeded) {
              currentPageHeight = 0; // Reset the page height if starting a new page
            }
            currentPageHeight += tableHeight;

            return (
              <View
                style={{
                  borderRadius: 5,
                  overflow: "hidden",
                  marginBottom: "20",
                }}
                wrap={false}
              >
                <Text
                  style={{
                    width: "100%",
                    backgroundColor: "#00586f",
                    textAlign: "left",
                    color: "white",
                    padding: "6px",
                    fontSize: "7px",
                    fontWeight: "600",
                    fontFamily: "Couture",
                  }}
                >
                  {table.title}
                </Text>

                <View
                  style={{
                    ...styles.table,
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    overflow: "hidden",
                  }}
                >
                  {/* Table Header */}
                  <View
                    style={{ ...styles.tableRow, backgroundColor: "#F1F1F1" }}
                  >
                    {tableColumns.map((col, colIndex) => (
                      <View
                        style={[
                          styles.tableCol,
                          {
                            width: `${100 / tableColumns.length}%`,
                            backgroundColor: "#7aa5ba",
                          },
                        ]}
                        key={colIndex}
                      >
                        <Text
                          style={{
                            ...styles.tableCell,
                            fontWeight: "bold",
                            fontSize: "5",
                            paddingTop: "1",
                            color: "white",
                            fontFamily: "Couture",
                          }}
                        >
                          {col}
                        </Text>
                      </View>
                    ))}
                  </View>

                  {/* Table Body */}
                  {table.rows.map((row, rowIndex) => {
                    let isTotal = false;
                    if (rowIndex + 1 === table.rows.length) {
                      isTotal = true;
                    }
                    return (
                      <View style={styles.tableRow} key={rowIndex}>
                        {tableColumns.map((_, colIndex) => (
                          <View
                            style={[
                              styles.tableCol,
                              { width: `${100 / tableColumns.length}%` },
                            ]}
                            key={colIndex}
                          >
                            <Text
                              style={{
                                ...styles.tableCell,

                                fontFamily: isTotal ? "Helvetica-Bold" : "",
                              }}
                            >
                              {
                                row[
                                  table.columns.indexOf(tableColumns[colIndex])
                                ]
                              }
                            </Text>
                          </View>
                        ))}
                      </View>
                    );
                  })}
                </View>
              </View>
            );
          });
        })}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10",
          }}
          wrap={false}
        >
          {tables["Top 10 Holdings"] && (
            <View
              style={{
                flex: 1,
              }}
            >
              {tables["Top 10 Holdings"].map((table, tableIndex) => {
                const splitTables = splitColumns(table.columns, 20); // Split large tables into chunks if more than 20 columns

                return splitTables.map((tableColumns, chunkIndex) => {
                  const tableHeight = estimateTableHeight(table.rows); // Estimate table height based on the number of rows

                  // Check if the table fits on the current page
                  const isNewPageNeeded =
                    currentPageHeight + tableHeight > pageHeight;

                  if (isNewPageNeeded) {
                    currentPageHeight = 0; // Reset the page height if starting a new page
                  }
                  currentPageHeight += tableHeight;

                  return (
                    <View
                      style={{
                        borderRadius: 5,
                        overflow: "hidden",
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          backgroundColor: "#00586f",
                          textAlign: "left",
                          color: "white",
                          padding: "7px",
                          fontSize: "7px",
                          fontWeight: "600",
                          fontFamily: "Couture",
                        }}
                      >
                        {table.title}
                      </Text>

                      <View
                        style={{
                          ...styles.table,
                          borderBottomLeftRadius: 5,
                          borderBottomRightRadius: 5,
                          overflow: "hidden",
                        }}
                      >
                        {/* Table Header */}
                        <View
                          style={{
                            ...styles.tableRow,
                            backgroundColor: "#F1F1F1",
                          }}
                        >
                          {tableColumns.map((col, colIndex) => (
                            <View
                              style={[
                                styles.tableCol,
                                {
                                  width: `${100 / tableColumns.length}%`,
                                  backgroundColor: "#7aa5ba",
                                },
                              ]}
                              key={colIndex}
                            >
                              <Text
                                style={{
                                  ...styles.tableCell,
                                  fontWeight: "bold",
                                  fontSize: "5",
                                  padding: "1",
                                  color: "white",
                                  fontFamily: "Couture",
                                }}
                              >
                                {col}
                              </Text>
                            </View>
                          ))}
                        </View>

                        {/* Table Body */}
                        {table.rows.map((row, rowIndex) => {
                          let isTotal = false;
                          if (rowIndex + 1 === table.rows.length) {
                            isTotal = true;
                          }
                          return (
                            <View style={styles.tableRow} key={rowIndex}>
                              {tableColumns.map((_, colIndex) => {
                                return (
                                  <View
                                    style={[
                                      styles.tableCol,
                                      {
                                        width: `${100 / tableColumns.length}%`,
                                        backgroundColor: "white",
                                      },
                                    ]}
                                    key={colIndex}
                                  >
                                    <Text
                                      style={{
                                        ...styles.tableCell,
                                        fontFamily: isTotal
                                          ? "Helvetica-Bold"
                                          : "",
                                      }}
                                    >
                                      {colIndex === 0 &&
                                        rowIndex + 1 != table.rows.length &&
                                        rowIndex + 1}{" "}
                                      {
                                        row[
                                          table.columns.indexOf(
                                            tableColumns[colIndex]
                                          )
                                        ]
                                      }
                                    </Text>
                                  </View>
                                );
                              })}
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  );
                });
              })}
            </View>
          )}

          {tables["Portfolio Summary"] && (
            <View
              style={{
                flex: 1,
              }}
            >
              {tables["Portfolio Summary"].map((table, tableIndex) => {
                const splitTables = splitColumns(table.columns, 20); // Split large tables into chunks if more than 20 columns

                return splitTables.map((tableColumns, chunkIndex) => {
                  const tableHeight = estimateTableHeight(table.rows); // Estimate table height based on the number of rows

                  // Check if the table fits on the current page
                  const isNewPageNeeded =
                    currentPageHeight + tableHeight > pageHeight;

                  if (isNewPageNeeded) {
                    currentPageHeight = 0; // Reset the page height if starting a new page
                  }
                  currentPageHeight += tableHeight;

                  return (
                    <View
                      style={{
                        borderRadius: 5,
                        overflow: "hidden",
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          backgroundColor: "#00586f",
                          textAlign: "left",
                          color: "white",
                          padding: "7px",
                          fontSize: "7px",
                          fontWeight: "600",
                          fontFamily: "Couture",
                        }}
                      >
                        {table.title}
                      </Text>

                      <View
                        style={{
                          ...styles.table,
                          borderBottomLeftRadius: 5,
                          borderBottomRightRadius: 5,
                          overflow: "hidden",
                        }}
                      >
                        {/* Table Header */}
                        <View
                          style={{
                            ...styles.tableRow,
                            backgroundColor: "#F1F1F1",
                          }}
                        >
                          {tableColumns.map((col, colIndex) => (
                            <View
                              style={[
                                styles.tableCol,
                                {
                                  width: `${100 / tableColumns.length}%`,
                                  backgroundColor: "#7aa5ba",
                                },
                              ]}
                              key={colIndex}
                            >
                              <Text
                                style={{
                                  ...styles.tableCell,
                                  fontWeight: "bold",
                                  fontSize: "5",
                                  padding: "1",
                                  color: "white",
                                  fontFamily: "Couture",
                                }}
                              >
                                {col}
                              </Text>
                            </View>
                          ))}
                        </View>

                        {/* Table Body */}
                        {table.rows.map((row, rowIndex) => {
                          let isTotal = false;
                          if (rowIndex + 1 === table.rows.length) {
                            isTotal = true;
                          }
                          return (
                            <View style={styles.tableRow} key={rowIndex}>
                              {tableColumns.map((_, colIndex) => (
                                <View
                                  style={[
                                    styles.tableCol,
                                    {
                                      width: `${100 / tableColumns.length}%`,
                                      backgroundColor: "white",
                                    },
                                  ]}
                                  key={colIndex}
                                >
                                  <Text
                                    style={{
                                      ...styles.tableCell,
                                      fontFamily: isTotal
                                        ? "Helvetica-Bold"
                                        : "",
                                    }}
                                  >
                                    {
                                      row[
                                        table.columns.indexOf(
                                          tableColumns[colIndex]
                                        )
                                      ]
                                    }
                                  </Text>
                                </View>
                              ))}
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  );
                });
              })}
            </View>
          )}
        </View>

        {tables["observation"] && (
          <View
            wrap={false}
            style={{
              marginTop: "20",
            }}
          >
            {tables["observation"]["paragraph"] && (
              <Text
                style={{
                  fontSize: "9",
                  fontFamily: "Helvetica-Bold",
                  marginBottom: "10",
                }}
              >
                High Level Observations:
              </Text>
            )}

            {tables["observation"]["paragraph"] && (
              <View
                style={{
                  marginBottom: "5px",
                }}
              >
                <Text
                  style={{
                    fontSize: "8",
                  }}
                >
                  {tables["observation"]["paragraph"]}
                </Text>
              </View>
            )}

            {tables["observation"]["positives"] && (
              <View
                style={{
                  marginBottom: "5px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Helvetica-Bold",
                    fontSize: "9",
                    marginBottom: "10",
                    marginLeft: "10",
                  }}
                >
                  Positives:
                </Text>

                {tables["observation"]["positives"].map((pos) => {
                  return (
                    <Text
                      key={pos}
                      style={{
                        fontSize: "8",
                        marginLeft: "20px",
                        marginBottom: "5",
                      }}
                    >
                      {pos}
                    </Text>
                  );
                })}
              </View>
            )}

            {tables["observation"]["negatives"] && (
              <View
                style={{
                  marginBottom: "5px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Helvetica-Bold",
                    fontSize: "9",
                    marginBottom: "10",
                    marginLeft: "10",
                  }}
                >
                  Negatives:
                </Text>

                {tables["observation"]["negatives"].map((pos) => {
                  return (
                    <Text
                      key={pos}
                      style={{
                        fontSize: "8",
                        marginLeft: "20",
                        marginBottom: "5",
                      }}
                    >
                      {pos}
                    </Text>
                  );
                })}
              </View>
            )}
          </View>
        )}
      </Page>
    </Document>
  );
};

const Report = ({ tableData, loading }) => {
  const [chartassetData, setChartassetData] = useState(null);
  const [chartsubData, setChartsubData] = useState(null);

  return (
    <Flex
      style={{
        position: "relative",
      }}
      width="100%"
      height="100%"
      direction="column"
    >
      {tableData["pie-chart"] &&
        tableData["pie-chart"]["pie-chart-asset"] &&
        tableData["pie-chart"]["pie-chart-sub"] && (
          <div
            className=""
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: -1,
              opacity: "0",
            }}
          >
            <AssetPieChart
              setChartData={setChartassetData}
              data={tableData["pie-chart"]["pie-chart-asset"]}
            />
            <AssetPieChart
              setChartData={setChartsubData}
              data={tableData["pie-chart"]["pie-chart-sub"]}
            />
          </div>
        )}

      {loading && (
        <Flex
          align="center"
          justify="center"
          className="loader"
          width="100%"
          height="100%"
        >
          <CircularProgress
            sx={{
              color: "grey",
            }}
            size={50}
          />
        </Flex>
      )}

      {/* PDF Viewer */}
      {!loading && tableData.tables && (
        <PDFViewer width="100%" height="100%">
          <MyDocument
            tables={tableData}
            chartData={chartassetData}
            chartsubData={chartsubData}
          />
        </PDFViewer>
      )}
    </Flex>
  );
};

export default Report;
