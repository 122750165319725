import CryptoJS from "crypto-js";
export const BASE_URL = "https://api.foliomax.com";
// export const BASE_URL = "http://127.0.0.1:25334";
export const encryptStorage = (str) => {
  let saltStr = String(process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET_KEY);
  return CryptoJS.AES.encrypt(str, saltStr).toString();
};
export const decryptStorage = (cipherStr) => {
  let saltStr = String(process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET_KEY);
  const decipher = CryptoJS.AES.decrypt(cipherStr, saltStr).toString(
    CryptoJS.enc.Utf8
  );
  return decipher.indexOf("{") === -1 ? decipher : JSON.parse(decipher);
};
