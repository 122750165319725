import React, { useEffect } from "react";
import { Flex } from "../../assets/styles/Chatbot";
import { Button, CircularProgress } from "@mui/material";
import { FaSquare } from "react-icons/fa6";

function Search({
  placeholder = "",
  loading = false,
  value,
  onChange,
  enterButton,
  onSearch,
  pause,
  pauseRef,
  HandlePause,
}) {
  const HandleKeyPress = (e) => {
    if (pause) {
      return;
    }

    if (e.key === "Enter") {
      onSearch(value);
    }
  };

  return (
    <Flex
      flex="1"
      width="100%"
      borderRadius={"6px"}
      align="center"
      className="chat-search-box"
      onKeyDown={HandleKeyPress}
    >
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />

      {!pause && (
        <Button
          className="chat-search-btn"
          onClick={() => {
            onSearch(value);
          }}
          disabled={loading}
        >
          {loading && <CircularProgress className="chat-search-progress" />}
          {enterButton}
        </Button>
      )}

      {pause && (
        <Button
          className="chat-search-btn"
          style={{
            padding: "0px",
          }}
          onClick={() => {
            pauseRef.current.pause = true;
            HandlePause(pauseRef.current.pause);
          }}
        >
          <Flex
            width="30px"
            height="30px"
            background="white"
            borderRadius="500px"
            align="center"
            justify="center"
          >
            <FaSquare
              style={{
                color: "black",
                fontSize: "0.7rem",
              }}
            />
          </Flex>
        </Button>
      )}
    </Flex>
  );
}

export default Search;
