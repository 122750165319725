import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Flex } from "../../assets/styles/Chatbot";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";
import NoData from "./NoData";
import { IoMdClose } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";

function PreviewExcel({ state, HandleExcelClose }) {
  const [data, setData] = useState(() => state.data.table);
  const [loading, setLoading] = useState(false);
  const temp = sessionStorage.getItem("token");
  const [columns, setColumns] = useState(() => Object.keys(data[0] || {}));

  const HandleDownloadFile = (response) => {
    const fileType = response.filename?.split(".")[1];
    const fileName = response.filename?.split(".")[0];
    try {
      const mimeType =
        fileType === "xlsx"
          ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          : "text/csv";

      const byteCharacters = atob(response.file);
      const byteNumbers = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteNumbers], { type: mimeType });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = `${fileName}.${fileType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setLoading(false);
    } catch (error) {
      console.log("failed to download file", error);
      setLoading(false);
    }
  };

  const handleInputChange = (rowIndex, columnKey, value) => {
    const newData = data.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, [columnKey]: value };
      }
      return row;
    });
    setData(newData);
  };

  const HandleDownload = () => {
    setLoading(true);
    axios
      .post(
        BASE_URL + "/save-table-file",
        {
          filetype: state.data.filetype,
          table: data,
        },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      )
      .then((response) => {
        if (response?.data.blocked) {
          toast.error("Please Login Again");
          sessionStorage.clear();
          window.location.reload();
        } else {
          if (response.status === 200) {
            HandleDownloadFile(response.data.data);
          } else {
            return;
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response?.data?.message);
      });
  };

  const addColumn = () => {
    const newColumnName = prompt("Enter new column name:");
    if (newColumnName) {
      const updatedData = data.map((row) => ({
        ...row,
        [newColumnName]: "", // Add new column with empty value
      }));
      setData(updatedData);
      setColumns([...columns, newColumnName]);
    }
  };

  const removeColumn = (columnName) => {
    const updatedData = data.map((row) => {
      const { [columnName]: _, ...rest } = row; // Destructure to remove the column
      return rest;
    });

    const updatedColumns = columns.filter((col) => col !== columnName);

    setData(updatedData);
    setColumns(updatedColumns);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "5px",
          maxWidth: "90%",
          width: "100%",
          height: "85%",
          background: "white",
          overflow: "visible",
        },
      }}
      onClose={HandleExcelClose}
      open={state.open}
    >
      <Box
        style={{
          background: "white",
          overflowY: "inherit",
          position: "relative",
          height: "100%",
          padding: "1rem 2rem",
        }}
      >
        <div
          className="cancle-btn"
          style={{
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "500px",
            background: "#AC2734 0% 0% no-repeat padding-box",
            position: "absolute",
            top: "-10px",
            right: "-10px",
            cursor: "pointer",
            zIndex: "1000",
          }}
          onClick={HandleExcelClose}
        >
          <RxCross2
            style={{
              fontSize: "1.2rem",
              color: "white",
              pointerEvents: "none",
            }}
          />
        </div>
        <DialogContent
          style={{
            padding: "0px",
            height: "100%",
            width: "100%",
            overflow: "inset",
          }}
        >
          <Flex
            direction="column"
            height="100%"
            width="100%"
            style={{
              position: "relative",
              overflowY: "inset",
            }}
          >
            <Flex
              padding="0rem 0rem 1rem 0rem"
              align="center"
              justify="flex-end"
              gap="1rem"
              width="100%"
            >
              <h1
                style={{
                  marginRight: "auto",
                  fontSize: "1.3rem",
                  color: "black",
                }}
              >
                Preview Data
              </h1>
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "0.6rem",
                  borderColor: "#1677ff",
                  color: "black",
                }}
                variant="outlined"
                onClick={() => {
                  setData(state.data.table);
                  setColumns(Object.keys(state.data.table[0] || {}));
                }}
              >
                Reset
              </Button>

              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "0.6rem",
                  backgroundColor: "#1677ff",
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
                variant="contained"
                onClick={HandleDownload}
                disabled={loading}
              >
                {loading && <CircularProgress size={"20px"} />}
                Download File
              </Button>

              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "0.6rem",
                  backgroundColor: "#1677ff",
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                }}
                variant="contained"
                onClick={addColumn}
              >
                Add Column
              </Button>
            </Flex>

            {!data.length && <NoData text="Data Not found" color="white" />}

            {data.length > 0 && (
              <Flex
                style={{
                  overflow: "auto",
                  height: "100%",
                }}
                className="excel_table_container"
                width="100%"
                height="100%"
              >
                <table className="excel_table">
                  <thead>
                    <tr className="excel_table_row">
                      {columns.map((col, i) => (
                        <th className="excel_table_head" key={`${col}-${i}`}>
                          {col}
                          <span
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                            className="remove-column-icon"
                            onClick={() => removeColumn(col)}
                          >
                            <IoMdClose
                              style={{
                                color: "white",
                                fontSize: "0.6rem",
                              }}
                            />
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {data.map((row, i) => (
                      <tr className="excel_table_row" key={i}>
                        {columns.map((rowVal, j) => (
                          <td
                            className="excel_table_data"
                            key={`${i}-${j}-${rowVal}`}
                          >
                            <input
                              type="text"
                              className="excel_table_input"
                              value={row[rowVal]}
                              onChange={(e) => {
                                handleInputChange(i, rowVal, e.target.value);
                              }}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Flex>
            )}
          </Flex>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

export default PreviewExcel;
