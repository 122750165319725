import React, { useState } from "react";
import CloseIcon from "../../assets/images/close.svg";
import { BsFiletypePdf } from "react-icons/bs";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { ReactAWSS3 } from "../../utils/FileUpload";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import getSingedUrl from "../../utils/SignedUrl";

/**
 * Description placeholder
 * @date 10/4/2023 - 11:18:14 PM
 *
 * @param {{ file: any; setFile: any; }} { file, setFile }
 * @returns {*}
 */
function UploadFileProgressBar({
  file,
  setFile,
  loading,
  setloading,
  handleUserTasks,
}) {
  const [progress, setProgress] = useState([]);
  const [error, setError] = useState({});
  const temp = sessionStorage.getItem("token");
  const navigate = useNavigate();

  const fileSubmitHandler = async () => {
    setloading(true);
    let uuid = v4();
    for (let fileItem = 0; fileItem < file.files.length; fileItem++) {
      const signedURL = await getSingedUrl(
        file.files[fileItem].name,
        file.files[fileItem].type,
        navigate,
        uuid
      );
      await ReactAWSS3(
        file.files[fileItem],
        uuid,
        setProgress,
        fileItem,
        signedURL
      );
    }
    const token = sessionStorage.getItem("token");
    try {
      axios
        .post(
          BASE_URL + "/upload-S3-file",
          { uuid: uuid, totalFiles: file.files.length },
          {
            headers: {
              "X-Auth-Token": token,
            },
            maxRedirects: 0,
          }
        )
        .then((response) => {
          let dragForm = document.getElementById("drag-and-drop-form");
          setloading(false);
          if (response?.data.error) {
            let message = response?.data.message;
            if (message === "Invalid Access Token.. Please login") {
            }
          }
          setProgress([]);
          if (response.data.success) {
            let err = {};
            let data = response?.data?.data;
            let fileStatusArray = [];
            for (let i = 0; i < data.length; i++) {
              if (data[i]?.filestatus) {
                let element = file.files;
                element.splice(i, 1);
                setFile(() => {
                  return {
                    files: element,
                    started: true,
                  };
                });
                fileStatusArray.push(data[i].fileStatus);
              } else {
                err[Object.keys(data[i])[0]] = data[i][Object.keys(data[i])[0]];
              }
            }
            if (fileStatusArray.length === response?.data?.data?.length) {
              setFile({
                files: [],
                started: false,
              });
            }
            setError(err);
            toast.success(response.data.message);
            dragForm.reset();
          }
          if (!response.data.success) {
            toast.info(response?.data?.message);
            dragForm.reset();
          }
        })
        .catch((err) => {
          setloading(false);
          if (!err?.response?.data) return toast.error("Internal Server Error");
          toast.error(err.response.data.message);
        })
        .finally(() => {
          handleUserTasks();
        });
    } catch (error) {
      toast.error(error);
      setloading(false);
    }
  };

  const deleteFileHandler = (e, value) => {
    let fileArray = [];
    let fileId = e.target.value;
    for (let i in file.files) {
      if (i !== fileId) fileArray.push(file.files[i]);
    }
    setFile({
      ...file,
      files: fileArray,
    });
    toast.success("Successfully Deleted");
  };

  return (
    <>
      {file.started && file.files.length > 0 && (
        <ul
          className="drag-and-drop-div"
          style={{
            border: "dashed 2px #50dfb2",
            padding: "5px",
            background: "white",
            flexDirection: "column",
            minHeight: "200px",
            maxHeight: "500px",
          }}
        >
          <div className="upload-file-button-container">
            {loading ? (
              <div className="uplaod-progress">
                <CircularProgress
                  style={{
                    color: "#50dfb2",
                    height: "30px",
                    width: "30px",
                  }}
                />
              </div>
            ) : (
              <button
                onClick={fileSubmitHandler}
                className="upload-file-button"
              >
                Submit
              </button>
            )}
          </div>
          <div
            style={{
              minHeight: "100px",
              maxHeight: "500px",
              overflow: "auto",
            }}
          >
            {file.started &&
              file.files.map((value, index) => {
                return (
                  <>
                    <li
                      className="upload-file-list"
                      style={{ borderBottom: "1px solid #b5b5b54f" }}
                    >
                      <div className="upload-file-list-icon">
                        <BsFiletypePdf />
                      </div>
                      <div className="upload-file-list-body-container">
                        <div className="upload-file-list-name-pc-container">
                          <div>{value.name}</div>
                          <div style={{ color: "red" }}>
                            {error[value.name]}
                          </div>
                          <div>{progress[index] ? progress[index] : 0}%</div>
                        </div>
                        <div className="upload-file-list-progressbar">
                          <progress
                            value={progress[index] ? progress[index] : 0}
                            max="100"
                          ></progress>
                        </div>
                      </div>
                      {!loading && (
                        <div className="upload-file-list-buttons">
                          <button
                            value={index}
                            onClick={(e) => deleteFileHandler(e, value)}
                          >
                            <img
                              src={CloseIcon}
                              alt="close button"
                              style={{ pointerEvents: "none" }}
                            />
                          </button>
                        </div>
                      )}
                    </li>
                  </>
                );
              })}
          </div>
        </ul>
      )}
    </>
  );
}

export default UploadFileProgressBar;
